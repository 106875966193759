const axios = require("axios")



export const sendSpareRequest = async (data) => {
  try {
    return await axios({
      url: "https://6hr8bsjr5g.execute-api.sa-east-1.amazonaws.com/dev/handle-spare-requisition",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
  } catch (error) {
    console.log(error)
    return error
  }
}